<template>
  <div>
    loading...
  </div>
</template>

<script>
export default {
  beforeMount () {
    this.validateToken()
  },
  methods: {
    validateToken () {
      this.$store.dispatch('updatePathParams', this.$route.params)
      const token = this.$route.params.token
      if (token) {
        this.logIn(token)
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    async logIn (token) {
      try { // this try is used because the token url convertion could be fail on -> const tokenData = atob(token), is just a work arround
        const tokenData = atob(token)
        // TODO: check if is necesary the next line because this portal is exclusive for minsa. the other idea could be a compatibility with futures external portals
        const company = tokenData.split(' ')[0]
        await this.$store.dispatch('authToken', { company, token, url: process.env.VUE_APP_API_URL_AGENTE })
        localStorage.setItem('company', company)
        localStorage.setItem('username', tokenData.split(' ')[1])
        localStorage.setItem('tokenAuth', this.$route.params.token)
        this.redirectView()
      } catch (error) {
        this.$router.push({ name: 'login' })
      }
    },
    redirectView () {
      const contact = this.search
        ? this.search.contact
        : this.$route.query.contact
      if (contact) {
        this.selectViewByContactLengt(contact)
      } else {
        this.$router.push({ name: 'externalContactList' })
      }
    },
    selectViewByContactLengt (contact) {
      const data = {
        pagina: 1,
        cantidad: 10,
        filtrobuscar: contact
      }

      this.$store.dispatch('contact/getAllContacts', data)
        .then((contactResponse) => {
          const contacts = contactResponse.data
          if (contacts.length === 1) {
            this.$router.push({ name: 'externalContact', query: { contactId: contacts[0].id } })
          } else {
            this.$router.push({ name: 'externalContactList', query: { contact: contact } })
          }
        })
        .catch((err) => this.$store.dispatch('snackbar/alert', { type: 'error', msg: `${this.$t('contact.components.snackbar.requestError')} : ${err}` }))
    }
  }
}
</script>

<style>
</style>
